import React, { Component } from 'react'

import ActivityLogSidePanel from 'components/LogBook/ActivityLogSidePanel'
import CommentSidePanel from 'components/Settings/CommentSidePanel'
import Drawer from 'components/Common/Drawer'
import ProhibitedItemsTable from './ProhibitedItemsTable'

import activityLogIcon from 'images/activity log.svg'
import commentsIcon from 'images/comment.svg'

class ProhibitedItems extends Component {
  constructor(props) {
    super(props)
    this.state = {
      view: 'COUNTRY',
    }
  }

  handleChangeDisplayMode = view => {
    this.setState({ view: view.toUpperCase() })
  }

  render() {
    const { view } = this.state

    const entityLabel = view === 'CUSTOMER' ? 'Customer' : 'Country'

    return (
      <React.Fragment>
        <ProhibitedItemsTable {...this.props} onViewChange={this.handleChangeDisplayMode} />
        <Drawer
          ref={ref => (this.drawer = ref)}
          tabs={[
            {
              key: 'logs',
              icon: <img src={activityLogIcon} alt="Acitivity Logs" />,
              content: ({ closeDrawer }) => (
                <ActivityLogSidePanel
                  entityId={view}
                  entity="ProhibitedItem"
                  entityLabel={entityLabel}
                  closeDrawer={closeDrawer}
                />
              ),
            },
            {
              key: 'comments',
              icon: <img src={commentsIcon} alt="Comments" />,
              content: ({ closeDrawer }) => (
                <CommentSidePanel referenceId={view} commentSource="prohibitedItem" closeDrawer={closeDrawer} />
              ),
            },
          ]}
        />
      </React.Fragment>
    )
  }
}

export default ProhibitedItems
