import { inject, observer } from 'mobx-react'
import React from 'react'

import { USER_ROLE_ACCESS_PATH } from 'definitions'
import ActivityLogSidePanel from 'components/LogBook/ActivityLogSidePanel'
import CommentSidePanel from 'components/Settings/CommentSidePanel'
import ContentContainer from 'components/Common/ContentContainer'
import Drawer from 'components/Common/Drawer'
import Loader from 'components/Common/Loader'
import UserRoleAccessForm from './UserRoleAccessForm'

import activityLogIcon from 'images/activity log.svg'
import commentsIcon from 'images/comment.svg'

class UserRoleAccessEdit extends React.Component {
  componentDidMount() {
    this.loadUserRoleAccess()
  }

  loadUserRoleAccess() {
    const {
      match: {
        params: { id },
      },
      userRoleAccessRepository,
    } = this.props

    userRoleAccessRepository.getById(id)
  }

  componentWillUnmount() {
    this.props.userRoleAccessRepository.clearCrudData()
    this.props.userRoleAccessRepository.clearErrorMessages()
  }

  handleCloseDrawer = () => {
    this.drawer && this.drawer.closeDrawer()
  }

  render() {
    const {
      userRoleAccessRepository: {
        CRUD: { loading, data },
      },
    } = this.props

    return loading && !data.id ? (
      <Loader />
    ) : (
      <React.Fragment>
        <ContentContainer
          title="Edit User Role Access"
          breadcrumbs={[
            {
              label: 'Home',
              href: '/',
            },
            {
              label: 'User Role Access',
              href: USER_ROLE_ACCESS_PATH,
            },
            {
              label: data ? data.role : '',
            },
          ]}
        >
          <UserRoleAccessForm {...this.props} data={data} isAdd={false} />
        </ContentContainer>
        <Drawer
          ref={ref => (this.drawer = ref)}
          tabs={[
            {
              key: 'logs',
              icon: <img src={activityLogIcon} alt="Acitivity Logs" />,
              content: (
                <ActivityLogSidePanel
                  entity="UserRoleAccess"
                  entityId={data.id}
                  entityLabel="User Role Access"
                  closeDrawer={this.handleCloseDrawer}
                />
              ),
            },
            {
              key: 'comments',
              icon: <img src={commentsIcon} alt="Comments" />,
              content: (
                <CommentSidePanel
                  referenceId={data.id}
                  commentSource="userRoleAccess"
                  closeDrawer={this.handleCloseDrawer}
                />
              ),
            },
          ]}
        />
      </React.Fragment>
    )
  }
}

export default inject('userRoleAccessRepository')(observer(UserRoleAccessEdit))
