/* @flow */
import React, { Component } from 'react'

import { Badge } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import ActivityLogSidePanel from 'components/LogBook/ActivityLogSidePanel'
import CommentSidePanel from 'components/Settings/CommentSidePanel'
import ContextMenu from 'components/Common/ContextMenu'
import Drawer from 'components/Common/Drawer'
import Form from './Form'
import IconButton from 'components/Common/Buttons/IconButton'
import InvalidForm from './InvalidForm'
import Loader from 'components/Common/Loader'

import activityLogIcon from 'images/activity log.svg'
import commentsIcon from 'images/comment.svg'

class CatalogPriceRulesEdit extends Component {
  state = {
    view: 'conditions',
  }
  componentWillMount() {
    const {
      match: { params },
      catalogPricingRuleRepository: { getById },
    } = this.props
    getById(params.id)
  }
  componentWillUnmount() {
    this.props.catalogPricingRuleRepository.clearCrudData()
  }
  handleChangeView = view => {
    this.setState({
      view,
    })
  }
  render() {
    const { view } = this.state
    const {
      catalogPricingRuleRepository: {
        CRUD: { data, loading },
      },
      match: {
        params: { id, type },
      },
    } = this.props

    const validRecord =
      data && ((type === 'codes' && data.couponRequired) || (type === 'rules' && !data.couponRequired))
    const title = type === 'rules' ? 'Edit Price Rules' : 'Edit Coupon Code'
    const breadcrumbs = data
      ? [
          {
            label: 'Home',
            href: '/',
          },
          {
            label: type === 'rules' ? 'Price Rules' : 'Coupon Codes',
            href: type === 'rules' ? '/pricing/rules' : '/pricing/codes',
          },
          {
            label: data.label,
          },
        ]
      : []

    return (
      <React.Fragment>
        {loading && !data.id && <Loader />}
        {!loading && !!data?.id && !validRecord && (
          <InvalidForm id={id} type={type} title={title} breadcrumbs={breadcrumbs} />
        )}
        {data.id && validRecord && (
          <div className="position-relative">
            <Form
              {...this.props}
              data={data}
              view={view}
              title={title}
              breadcrumbs={breadcrumbs}
              actions={
                type === 'codes' && (
                  <>
                    <Badge color="dark" className="text-uppercase p-2">
                      {view === 'conditions' ? 'Conditions' : 'Coupon Codes'}
                    </Badge>
                    <ContextMenu
                      disabled={loading}
                      dropDownToggle={<IconButton icon="md-apps" />}
                      menus={[
                        {
                          text: 'Conditions',
                          onClick: () => {
                            this.handleChangeView('conditions')
                          },
                        },
                        {
                          text: 'Coupon Codes',
                          onClick: () => {
                            this.handleChangeView('coupons')
                          },
                        },
                      ]}
                      plain
                    />
                  </>
                )
              }
            />
            <Drawer
              ref={ref => (this.drawer = ref)}
              tabs={[
                {
                  key: 'logs',
                  icon: <img src={activityLogIcon} alt="Acitivity Logs" />,
                  content: ({ closeDrawer }) => (
                    <ActivityLogSidePanel
                      entity="PricingRule"
                      entityId={data.id}
                      entityLabel="Price Rule"
                      closeDrawer={closeDrawer}
                    />
                  ),
                },
                {
                  key: 'comments',
                  icon: <img src={commentsIcon} alt="Comments" />,
                  content: ({ closeDrawer }) => (
                    <CommentSidePanel referenceId={data.id} commentSource="pricingRules" closeDrawer={closeDrawer} />
                  ),
                },
              ]}
            />
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default decorate(inject('catalogPricingRuleRepository')(observer(CatalogPriceRulesEdit)), {})
