import React, { Component } from 'react'

import { Col, Row } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { ACTIVE_STATUS, INACTIVE_STATUS, SHIPPING_PATH } from 'definitions'
import { allowedToOrderRole } from 'helpers/Auth'
import { isNull } from 'helpers/Common'
import { titleize } from 'helpers/String'
import Checkbox from 'components/Common/Form/Checkbox'
import ContentContainer from 'components/Common/ContentContainer'
import Form from 'components/Common/Form/index'
import FormActions from 'components/Common/Buttons/FormActions'
import FormContainer from 'components/Common/Form/Container'
import ReactSelect from 'components/Common/Form/ReactSelect'

import ShippingRateTable from 'components/Shipping/ShippingRateTable'

class ShippingForm extends Component {
  state = {
    promptOnExit: false,
  }
  constructor(props) {
    super(props)

    const {
      data: {
        status = ACTIVE_STATUS,
        stores = [],
        customerOrganizationGroups: groups = [],
        shippingCarrier,
        shippingService,
        customerProvidedInd,
        priorityShippingInd,
      } = {},
    } = props
    const rules = 'required'
    const hooks = { onChange: () => this.promptOnExit(true) }
    const fields = {
      status: {
        label: titleize(status),
        type: 'switch',
        value: status === ACTIVE_STATUS,
        hooks,
      },
      stores: {
        label: 'Stores',
        type: 'select',
        rules,
        value: stores,
        hooks,
      },
      groups: {
        label: 'Customer Groups',
        type: 'select',
        rules,
        value: groups,
        hooks,
      },
      shippingCarrier: {
        label: 'Shipping Method',
        type: 'select',
        rules,
        value: shippingCarrier,
        hooks,
      },
      shippingService: {
        label: 'Shipping Service',
        type: 'select',
        value: shippingService,
        hooks,
      },
      customerProvidedInd: {
        label: 'Own Shipping Arrangement',
        type: 'checkbox',
        rules,
        value: customerProvidedInd,
        hooks,
      },
      priorityShippingInd: {
        label: 'Peplink Services',
        type: 'checkbox',
        rules,
        value: priorityShippingInd,
        hooks,
      },
    }

    this.form = new Form({ fields }, { name: 'ShippingForm', handleSubmit: this.handleSubmit })
    this.saving = false
  }
  backToIndex = () => {
    this.props.history.push(SHIPPING_PATH)
  }
  handleSubmit = () => {
    const { stores, groups, status, shippingCarrier, shippingService, ...actualValues } = this.form.values()
    const {
      shippingRepository: {
        create,
        patch,
        CRUD: {
          data: { id = null },
        },
      },
      isAdd,
    } = this.props

    const requestAction = isAdd ? create : patch

    this.saving = true
    requestAction(
      {
        ...actualValues,
        id,
        status: status ? ACTIVE_STATUS : INACTIVE_STATUS,
        stores: stores.map(item => ({ id: item.id, name: item.name })),
        customerOrganizationGroups: groups.map(item => ({ id: item.id, name: item.name })),
        shippingCarrier: isNull(shippingCarrier) && { id: shippingCarrier.id },
        shippingService: isNull(shippingService) && { id: shippingService.id },
      },
      result => {
        this.promptOnExit(false)
        if (isAdd && result) {
          this.props.history.push(`${SHIPPING_PATH}/${result.id}`)
        } else {
          this.props.history.push(`${SHIPPING_PATH}`)
        }
      }
    )
  }
  promptOnExit = promptOnExit => {
    this.setState({ promptOnExit })
  }
  render() {
    const {
      shippingRepository: { CRUD: { data, errors, loading, submitting } = {} },
      shippingRateRepository,
      storeRepository,
      customerGroupRepository,
      commonRepository,
      title,
      breadcrumbLabel,
      isAdd,
      onDisplayModeChanged,
    } = this.props

    const isSubmitting = submitting || shippingRateRepository.CRUD.submitting

    return (
      <ContentContainer
        title={title}
        breadcrumbLabel={breadcrumbLabel}
        promptOnExit={isSubmitting || this.state.promptOnExit}
        promptOnExitIsConfirm={!isSubmitting}
      >
        <Row>
          <Col lg={4}>
            <FormContainer
              title="Shipping Details"
              onSubmit={e => this.form.onSubmit(e, { onSuccess: this.handleSubmit })}
              errors={errors}
              style={{ marginBottom: '65px' }}
              actions={
                <FormActions
                  loading={loading}
                  loadingMessage={`${this.saving ? 'Saving' : 'Loading'} please wait...`}
                  confirmLabel="Save"
                  cancelLabel={data.id ? 'Close' : 'Cancel'}
                  onCancel={this.backToIndex}
                  hideConfirm={!allowedToOrderRole()}
                />
              }
            >
              <ReactSelect
                field={this.form.$('shippingCarrier')}
                serverSide
                search={params => commonRepository.reactSelectSearch(params, 'shippingCarrier')}
                options={{
                  valueKey: 'value',
                  labelKey: 'label',
                  defaultOptions: true,
                }}
              />
              <ReactSelect
                field={this.form.$('shippingService')}
                serverSide
                search={params => commonRepository.reactSelectSearch(params, 'shippingService')}
                options={{
                  valueKey: 'value',
                  labelKey: 'label',
                  defaultOptions: true,
                }}
              />
              <ReactSelect
                field={this.form.$('stores')}
                customLabelKey={['name', 'id']}
                serverSide
                search={storeRepository.reactSelectSearch}
                options={{
                  isMulti: true,
                  valueKey: 'id',
                  labelKey: 'name',
                  defaultOptions: true,
                }}
              />
              <ReactSelect
                field={this.form.$('groups')}
                customLabelKey={['name', 'id']}
                serverSide
                search={customerGroupRepository.reactSelectSearch}
                options={{
                  isMulti: true,
                  valueKey: 'id',
                  labelKey: 'name',
                  defaultOptions: true,
                }}
              />
              <Checkbox field={this.form.$('customerProvidedInd')} />
              <Checkbox field={this.form.$('priorityShippingInd')} />
              <Checkbox field={this.form.$('status')} label={this.form.$('status').value ? 'Active' : 'Inactive'} />
            </FormContainer>
          </Col>
          {!isAdd && (
            <ShippingRateTable
              shippingDetailId={data.id}
              promptOnExit={this.promptOnExit}
              onDisplayModeChanged={onDisplayModeChanged}
            />
          )}
        </Row>
      </ContentContainer>
    )
  }
}

export default decorate(
  inject(
    'shippingRepository',
    'shippingRateRepository',
    'storeRepository',
    'customerGroupRepository',
    'commonRepository'
  )(observer(ShippingForm)),
  {}
)
