import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import activityLogIcon from 'images/activity log.svg'
import ActivityLogSidePanel from 'components/LogBook/ActivityLogSidePanel'
import CommentSidePanel from 'components/Settings/CommentSidePanel'
import Drawer from 'components/Common/Drawer'
import Form from './Form'
import Loader from 'components/Common/Loader'

import commentsIcon from 'images/comment.svg'

class OrderChargesEdit extends Component {
  componentWillMount() {
    const {
      orderChargeRepository: { getById },
      match: {
        params: { id },
      },
    } = this.props

    getById(id)
  }
  componentWillUnmount() {
    this.props.orderChargeRepository.clearCrudData()
  }
  render() {
    const {
      orderChargeRepository: {
        CRUD: { loading, data },
      },
    } = this.props

    return loading ? (
      <Loader />
    ) : (
      <React.Fragment>
        <Form {...this.props} data={data} title="Edit Payment Surcharge" />
        <Drawer
          ref={ref => (this.drawer = ref)}
          tabs={[
            {
              key: 'logs',
              icon: <img src={activityLogIcon} alt="Acitivity Logs" />,
              content: ({ closeDrawer }) => (
                <ActivityLogSidePanel
                  entity="AdditionalChargeRule"
                  entityId={data.id}
                  entityLabel="Payment Surcharge"
                  closeDrawer={closeDrawer}
                />
              ),
            },
            {
              key: 'comments',
              icon: <img src={commentsIcon} alt="Comments" />,
              content: (
                <CommentSidePanel
                  referenceId={data.id}
                  commentSource="paymentSurcharge"
                  closeDrawer={this.handleCloseDrawer}
                />
              ),
            },
          ]}
        />
      </React.Fragment>
    )
  }
}

export default decorate(inject('orderChargeRepository')(observer(OrderChargesEdit)), {})
