/* @flow */
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import cx from 'classnames'
import styled from 'styled-components'

import ActivityLogSidePanel from 'components/LogBook/ActivityLogSidePanel'
import CommentSidePanel from 'components/Settings/CommentSidePanel'
import Drawer from 'components/Common/Drawer'
import Loader from 'components/Common/Loader'
import React, { Component } from 'react'
import ShippingDetail from './ShippingDetail'

import activityLogIcon from 'images/activity log.svg'
import commentsIcon from 'images/comment.svg'

const shippingLogData = {
  activityLogData: {
    entity: 'ShippingDetail',
    entityLabel: 'Shipping Detail',
  },
  commentData: {
    commentSource: 'shippingDetail',
  },
}

class ShippingEdit extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ...shippingLogData,
    }

    this.loadShippingDetail()
  }

  loadShippingDetail() {
    const {
      match: {
        params: { id },
      },
      shippingRepository,
    } = this.props

    shippingRepository.getById(id)
  }

  componentWillUnmount() {
    this.props.shippingRepository.clearCrudData()
    this.props.shippingRepository.clearErrorMessages()
  }

  handleChangeDisplayMode = (mode, id) => {
    if (id) {
      switch (mode) {
        case 'shipping-detail':
          this.setState({
            activityLogData: {
              ...shippingLogData.activityLogData,
              entityId: id,
            },
            commentData: {
              ...shippingLogData.commentData,
              referenceId: id,
            },
          })
          break
        case 'shipping-rate':
          this.setState({
            activityLogData: {
              entity: 'ShippingRate',
              entityId: id,
              entityLabel: 'Shipping Rate',
            },
            commentData: {
              referenceId: id,
              commentSource: 'shippingRate',
            },
          })
          break
        default:
          this.setState({ ...shippingLogData })
          break
      }
    } else {
      this.setState({ ...shippingLogData })
    }
  }

  handleOnActiviityLogModalOpen = () => {
    const modalWrapperEl = window.document.querySelector('.log-book-modal-wrapper')
    if (modalWrapperEl) {
      modalWrapperEl.parentElement.style.zIndex = 1071
    }
  }

  handleCloseDrawer = () => {
    this.drawer && this.drawer.closeDrawer()
  }

  render() {
    const {
      shippingRepository: {
        CRUD: { loading, data },
      },
    } = this.props
    const { activityLogData, commentData } = this.state

    return loading && !data.id ? (
      <Loader />
    ) : (
      <React.Fragment>
        <ShippingDetail
          {...this.props}
          title="Edit Shipping"
          breadcrumbLabel={data && data.shippingCarrier ? data.shippingCarrier.label : ''}
          data={data}
          onDisplayModeChanged={this.handleChangeDisplayMode}
        />
        <DrawerStyled
          ref={ref => (this.drawer = ref)}
          className={cx(commentData.commentSource !== 'shippingDetail' && 'topmost')}
          tabs={[
            {
              key: 'logs',
              icon: <img src={activityLogIcon} alt="Acitivity Logs" />,
              content: (
                <ActivityLogSidePanel
                  entity={activityLogData.entity}
                  entityId={activityLogData.entityId || data.id}
                  entityLabel={activityLogData.entityLabel}
                  closeDrawer={this.handleCloseDrawer}
                  onLogModalOpen={this.handleOnActiviityLogModalOpen}
                />
              ),
            },
            {
              key: 'comments',
              icon: <img src={commentsIcon} alt="Comments" />,
              content: (
                <CommentSidePanel
                  referenceId={commentData.referenceId || data.id}
                  commentSource={commentData.commentSource}
                  closeDrawer={this.handleCloseDrawer}
                />
              ),
            },
          ]}
        />
      </React.Fragment>
    )
  }
}

const DrawerStyled = styled(Drawer)`
  &&& {
    &.topmost {
      z-index: 1060;
      .sidebar {
        top: 0;
        height: 100%;
      }
    }
  }
`

export default decorate(inject('shippingRepository')(observer(ShippingEdit)), {})
