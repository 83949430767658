import React from 'react'
import styled from 'styled-components'

import * as definitions from 'definitions'
import { Visible } from 'helpers/Auth'
import Checkbox from 'components/Common/Form/Checkbox'
import ContextMenu from 'components/Common/ContextMenu'
import IconButton from 'components/Common/Buttons/IconButton'
import Loader from 'components/Common/Loader'
import Status from 'components/Common/Status'

export default ({
  data: { orderNumber, status, orderSource } = {},
  form,
  readOnly,
  readOnlyCoupon,
  handleEditMode,
  handleCouponEditMode,
  handleCancelOrder,
  handleSaveDraft,
  handleDiscardChanges,
  handleSaveOrder,
  handleConvertQuotation,
  handleSaveCoupon,
  handleSendEmail,
  loading,
}) => {
  const menus = React.useMemo(() => {
    const isNew = !status || status === definitions.ORDER_STATUS_NEW
    const approved = status === definitions.ORDER_STATUS_APPROVED_QUOTATION
    const pendingApproval = status === definitions.ORDER_STATUS_PENDING_APPROVAL
    const hasOrderNumber = !!orderNumber
    const isSimulated = orderSource === definitions.ORDER_SOURCE.COTERM_SIMULATOR_PARTNER_PAVILION

    if (isNew) {
      return [
        { text: 'Edit Quotation', onClick: handleEditMode, hidden: !readOnly || isSimulated },
        { text: 'Discard Changes', onClick: handleDiscardChanges, hidden: readOnly },
        { text: 'Save as Draft', onClick: handleSaveDraft, hidden: readOnly || hasOrderNumber },
        { text: 'Submit Quotation', onClick: handleSaveOrder },
        { text: 'Cancel Quotation', onClick: handleCancelOrder },
      ]
    } else if (pendingApproval) {
      return [
        { text: 'Edit Quotation', onClick: handleEditMode, hidden: !readOnly || isSimulated },
        { text: 'Discard Changes', onClick: handleDiscardChanges, hidden: readOnly },
        { text: 'Submit Quotation', onClick: handleSaveOrder, hidden: readOnly },
        { text: 'Cancel Quotation', onClick: handleCancelOrder },
      ]
    } else if (approved) {
      return [
        { text: 'Convert Quotation', onClick: handleConvertQuotation },
        { text: 'Cancel Quotation', onClick: handleCancelOrder },
        { text: 'Edit Coupon', onClick: handleCouponEditMode, hidden: !readOnlyCoupon || isSimulated },
        { text: 'Update Coupon', onClick: handleSaveCoupon, hidden: readOnlyCoupon },
        { text: 'Send Email', onClick: handleSendEmail },
      ]
    } else {
      return []
    }
  }, [status, readOnly, readOnlyCoupon])

  const isDisabled = !menus.length

  return (
    <ActionsStyled>
      <Checkbox
        disabled={readOnly}
        readOnly={readOnly}
        field={form.$('sendQuotationEmailInd')}
        className="mr-3"
        inputClassName="mt-0"
      />
      <Checkbox
        disabled={readOnly}
        readOnly={readOnly}
        field={form.$('skipSNWarrantyInd')}
        className="mr-3 mt-0 col-auto p-0"
      />
      <h6 className="mb-0">
        <Status status={status} isTable={false} />
      </h6>
      {loading && <Loader text="" className="ml-2 p-0" />}
      {!isDisabled && !loading && (
        <Visible toOrderProcessRole>
          <ContextMenu
            className="ml-2"
            plain
            dropDownToggle={<IconButton icon="md-more" className="icon-more" iconSize="large" />}
            menus={menus}
          />
        </Visible>
      )}
    </ActionsStyled>
  )
}

const ActionsStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  label {
    cursor: pointer;
  }
  .btn-outline-primary {
    width: 150px;
    small {
      font-size: 90%;
      font-weight: 600;
    }
  }
  .icon-more {
    color: #bdbdbd;
  }
  div.subscription-period {
    font-size: 12px;
    strong {
      background-color: ${props => props.theme.lightDark};
    }
  }
`
